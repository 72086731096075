<template>
  <div class="church-presentation-template">
    <page-body class>
      <h1 class="sm mb-4">
        {{ translations.tcAddEditChurchPresenter }}
      </h1>
      <section class="section-1 bg-block mb-4">
        <div class="body">
          <b-form-group>
            <b-row>
              <b-col sm="4">
                <div style="font-weight: 600">
                  {{ translations.tcClickPresenterToEdit }}
                </div>
                <div class="candidate_list">
                  <div v-for="(cnd, idx) in gideonCandidates" :key="cnd.ind_key" class="candidates">
                    <div
                      style="padding-left: 10px"
                      :id="`cnd_${idx}`"
                      :class="[cnd.ind_key === selected_candidate.ind_key ? 'selected_blue' : '']"
                      @click="handleCandidateSelect(cnd.ind_key)"
                    >
                      {{ cnd.name }}
                      <span>{{ cnd.qualified ? `*` : `` }}{{ cnd.presentation_media_flag ? `*` : `` }}</span>
                    </div>
                  </div>
                </div>
                <div style="display: inline-flex; margin-top: 10px">
                  <strong>
                    * = {{ translations.tcQualifiedForSpeaking }}
                    <br />
                    ** = {{ translations.tcQualifiedForMediaPresentations }}
                  </strong>
                </div>
              </b-col>
              <b-col sm="8">
                <b-row>
                  <b-col sm="12">
                    <h2 class="presenter">{{ selected_candidate.name }}</h2>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" style="">
                    <strong>{{ translations.tcQualifiedDate }}</strong>
                    <a
                      v-if="!!selected_candidate.qualified_date"
                      href="#"
                      class="mb-3 ml-2"
                      @click.prevent="handleClearClick"
                    >
                      {{ translations.tcClear }}
                    </a>
                    <b-form-datepicker
                      id="qualifiedDate"
                      v-model="selected_candidate.qualified_date"
                      class="mb-2 contact_date"
                      :locale="prefCulture"
                      :label-help="translations.tcCalHelp"
                      :label-no-date-selected="translations.tcDatePickerPlaceholder"
                      :placeholder="translations.tcDatePickerPlaceholder"
                      :show-decade-nav="showDecadeNav"
                      :hide-header="hideHeader"
                    >
                      <template #button-content style="padding: 12px">
                        <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                      </template>
                    </b-form-datepicker>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <strong>{{ translations.tcMedia }}</strong>
                    <b-form-checkbox
                      id="media"
                      v-model="selected_candidate.presentation_media_flag"
                      name="media"
                      value="true"
                      class="mb-3"
                      unchecked-value="false"
                    ></b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6">
                    <strong>{{ translations.tcComments }}</strong>
                    <b-form-textarea
                      id="textarea"
                      v-model="selected_candidate.ixa_note"
                      rows="2"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      v-if="iCanSee(secured_church_presenter_edit_controls.edit_church_presenter_button)"
                      style="margin-top: 20px"
                      @click="handleSaveClick"
                      variant="primary"
                      :disabled="!saveButtonEnabled"
                      class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0"
                    >
                      {{ translations.tcSave }}
                    </b-button>
                    <b-button
                      v-if="iCanSee(secured_church_presenter_delete_controls.delete_church_presenter_button)"
                      style="margin-top: 20px"
                      @click="handleDeleteClick"
                      variant="primary"
                      :disabled="!removeButtonEnabled"
                      class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0"
                    >
                      {{ translations.tcDelete }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form-group>
        </div>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'
import { calendarMixin } from '@/mixins/calendarMixin'

export default {
  name: 'add-church-presenter',
  mixins: [translationMixin, calendarMixin],
  data() {
    return {
      showDecadeNav: true,
      hideHeader: true,
      translations: {},
      presetDate: null,
      selectedCandidateInitial: null,
      selected_candidate: {},
      empty_candidate: {
        ind_key: '',
        name: '',
        sort_name: '',
        qualified: false,
        ixa_key: null,
        ixo_key: null,
        qualified_date: null,
        presentation_media_flag: false,
        camp_name: '',
        ixa_note: null,
        ixa_scripture_blitz_date: null,
        ixa_scripture_blitz_title: null,
        ixa_start_date: null,
        ind_last_name: '',
        ind_first_name: '',
        presentation_media_flag_string: '',
        ixa_delete_flag: 0,
      },
      successful_add: true,
      secured_church_presenter_delete_controls: {
        delete_church_presenter_button: 'c4fa0734-0a9b-428f-96bb-02833d329aef',
      },
      secured_church_presenter_edit_controls: {
        edit_church_presenter_button: '70da6484-25f2-448f-906b-6b73c091f795',
      },
    }
  },
  methods: {
    ...mapActions({
      addChurchPresenter: 'churchMinistry/addChurchPresenter',
      getAllPresentersByCamp: 'churchMinistry/getAllPresentersByCamp',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async handleDeleteClick() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.tcOkayToRemovePresenter,
        confirmButtonText: this.translations.tcOk,
        cancelButtonText: this.translations.tcCancel,
      }).then((result) => {
        if (result.value) {
          this.selected_candidate.ixa_delete_flag = true
          this.handleSaveClick()
        }
      })
    },
    handleClearClick() {
      this.selected_candidate.qualified_date = null
    },
    async handleSaveClick() {
      await this.setLoadingStatus(true)
      this.successful_add = await this.addChurchPresenter(this.selected_candidate)

      if (this.successful_add) {
        await this.page_load()
      }

      this.setLoadingStatus(false)
      await this.$swal({
        icon: this.successful_add ? 'success' : 'error',
        text: this.successful_add ? this.translations.tcEditSuccessful : this.translations.tcEditFailed,
        confirmButtonText: this.translations.tcOk,
      })

      if (this.successful_add) {
        this.$router.push('/programs/cm/presenters')
      }
    },
    async handleCandidateSelect(key) {
      const candidate = this.gideonCandidates.find((cf) => cf.ind_key === key)
      this.selected_candidate = {
        ...candidate,
        qualified_date: !!candidate.qualified_date ? this.formatForDatePicker(candidate.qualified_date) : null,
      }
      // take snapshot of object so we can do a diff later
      this.selectedCandidateInitial = { ...this.selected_candidate }
    },
    async page_load() {
      try {
        await Promise.all([
          await this.setLoadingStatus(true),
          await this.getTranslations(),
          await this.getAllPresentersByCamp(),
          await (this.selected_candidate = this.empty_candidate),
          (this.presetDate = null),
        ])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
  },
  async created() {
    await this.page_load()
  },
  computed: {
    ...mapGetters({
      candidates: 'churchMinistry/all_presenters',
      iCanSee: 'user/iCanSee',
      prefCulture: 'user/userPreferredCulture',
    }),
    gideonCandidates() {
      return this.candidates.filter(
        (gc) => gc.met_key === constantData.member_types.find((mt) => mt.text === 'Gideon').value
      )
    },
    removeButtonEnabled() {
      return !!this.selectedCandidateInitial && !!this.selectedCandidateInitial.qualified_date
    },
    saveButtonEnabled() {
      return !!this.selected_candidate.name && !!this.selected_candidate.qualified_date
    },
  },
  components: {
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.presenter {
  font-family: 'DIN 1451 W01 Engschrift', sans-serif;
  margin: 20px 0;
}

.selected_blue {
  background-color: #003946;
  color: #ffffff;
}
.candidates {
  line-height: 34px;
}
.candidate_list {
  height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 1em;
  border: 1px solid #000;
}

h3 {
  line-height: 1;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;
  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;
  @include breakpoint(sm) {
    width: 24px;
  }
  input {
    order: 2;
  }
  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;
    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;
  @include breakpoint(sm) {
    flex-wrap: wrap;
  }
  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;
    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }
  .g-col-2 {
    max-width: 247px;
    width: 100%;
    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;
    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;
    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;
  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

.contact_date {
  width: 55%;
  @include breakpoint(sm) {
    width: 100%;
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
